<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <ValidationObserver v-slot="{ invalid }" :disabled="true">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <h4 class="modal-title">Display austauschen</h4>
          </div>
          <div class="modal-body">
            <div class="col-xl-12 pr-4">
              <!-- START Stammdaten -->
              <container-headline
                :headline="$t('global.masterdata')"
                :col="6"
              ></container-headline>
              <div class="row mb-3">
                <div class="col-xl-12 block br-t-l-0">
                  <div class="eingabe-panel-kopf p-3">
                    <div class="row">
                      <div class="col-xl-4">
                        <div class="form-group">
                          <input
                            v-model="displayNr"
                            class="form-control"
                            placeholder=""
                          />
                          <label>Neue Display Nummer</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ENDE Stammdaten -->
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              :disabled="invalid"
              class="btn btn-primary"
              @click="speichern"
            >
              Speichern
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import ContainerHeadline from "@/components/ContainerHeadline";

import ModalCloseButton from "@/components/global/ModalCloseButton";

export default {
  name: "RmaModal",
  components: {
    ContainerHeadline,

    ModalCloseButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    arbeitsplatz: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shown: false,
      bezeichnung: null,
      displayNr: null,
      raum: null,
      displayStatus: null,
    };
  },
  computed: {
    person: function () {
      let personen = [];
      this.dozentenbuchungen.forEach((db) => personen.push(db.person));
      return personen;
    },

    dozentenGewaehlt: function () {
      return this.findeDozentenbuchungenAufTermin(this.dozenten);
    },

    dozent1PersonName: function () {
      let nameDozent1 = "";

      if (this.dozenten.length > 0)
        nameDozent1 = this.dozentenGewaehlt[0].person.personName;

      return nameDozent1;
    },
  },
  watch: {
    von: function () {
      this.bis = this.von;
    },
  },
  mounted() {
    this.$bus.$on("openRmaModal", (id) => {
      if (!this.shown) this.openModal(id);
    });
  },
  methods: {
    updateVon(val) {
      this.von = val;
      if (this.bis == null || this.bis == "") this.bis = val;
    },

    updateBis(val) {
      this.bis = val;
    },

    openModal(id) {
      this.shown = true;

      const thisId = id ? id : this.id;

      if (this.arbeitsplatz) {
        Api.get("arbeitsplatz/", {
          params: { id: this.arbeitsplatz.id },
        }).then((response) => {
          this.bezeichnung = response.data.bezeichnung;
          this.raum = response.data.raum;
        });
      }

      $("#" + thisId).modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },

    speichern() {
      console.log("speichern");
      var json = {
        bezeichnung: this.bezeichnung,
        displayNr: this.displayNr,
        raum: this.raum,
      };

      console.log(json);
      Api.put("arbeitsplatz/", json, {
        params: { id: this.arbeitsplatz.id, type: "rma" },
      })
        .then(() => {
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: "Arbeitsplatz erfolgreich bearbeitet.",
          });

          this.closeModal();
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.$parent.getArbeitsplatz();
        });
    },
  },
};
</script>

<style>
</style>
