<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar" title=">BX:EDUCATION"></navbar>

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="'Arbeitsplatz'"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button class="btn btn-primary ml-2" @click="abbrechen">
                    {{ $t("global.cancel") }}
                  </button>
                  <button
                    class="btn btn-danger ml-2"
                    @click="oeffneRmaModal"
                    v-if="isDESKAAdmin"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-trash-alt"
                      class="mr-2"
                    />
                    <span>Display Austauschen</span>
                  </button>
                  <button
                    class="btn btn-success ml-2"
                    @click="displayFreigeben"
                    v-if="isDESKAAdmin"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-trash-alt"
                      class="mr-2"
                    />
                    <span>Display freigeben</span>
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_ressourcen.delete"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-trash-alt"
                      class="mr-2"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- START Linke Seite -->

        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Stammdaten -->
          <container-headline
            :headline="$t('global.masterdata')"
            :col="6"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model.trim="arbeitsplatz.bezeichnung"
                        required
                        class="form-control"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="arbeitsplatz.raum"
                        label="bezeichnung"
                        :disabled="!isDESKAAdmin"
                        :options="raeume"
                        :reduce="(r) => r.id"
                        placeholder="Raum"
                        required
                      >
                        <span slot="no-options">{{ $t("notification.taptosearch") }}</span>
                      </v-select>
                      <label>Raum</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6">
                    <div
                      v-if="
                        arbeitsplatz.displayStatus == 'Label nicht verknüpft' ||
                        arbeitsplatz.displayStatus == null
                      "
                      class="form-group"
                    >
                      <input
                        v-model="arbeitsplatz.displayNr"
                        v-bind:class="
                          arbeitsplatz.displayStatus == 'Label nicht verknüpft'
                            ? 'border-danger'
                            : true
                        "
                        class="form-control"
                        :readonly="!isDESKAAdmin"
                        required
                        placeholder=" "
                      />
                      <label
                        v-bind:class="
                          arbeitsplatz.displayStatus == 'Label nicht verknüpft'
                            ? 'text-danger'
                            : true
                        "
                        >Display Nummer</label
                      >
                    </div>
                    <div
                      v-if="arbeitsplatz.displayStatus == 'Label verknüpft'"
                      class="form-group"
                    >
                      <input
                        v-model="arbeitsplatz.displayNr"
                        v-bind:class="
                          arbeitsplatz.displayStatus == 'Label nicht verknüpft'
                            ? 'border-danger'
                            : true
                        "
                        class="form-control"
                        :readonly="true"
                        required
                        placeholder=" "
                      />
                      <label
                        v-bind:class="
                          arbeitsplatz.displayStatus == 'Label nicht verknüpft'
                            ? 'text-danger'
                            : true
                        "
                        >Display Nummer</label
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model="arbeitsplatz.displayStatus"
                        class="form-control"
                        :readonly="true"
                        placeholder=" "
                      />
                      <label
                        v-bind:class="
                          arbeitsplatz.displayStatus == 'Label nicht verknüpft'
                            ? 'text-danger'
                            : true
                        "
                        >Display Status</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Stammdaten -->
        </div>
        <!-- ENDE linke Seite -->

        <!-- ENDE Rechte Seite -->
        <div v-if="id" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'ArbeitsplatzMitIdUndAnzeige',
                      params: { id: id, anzeige: 0 },
                    }"
                    >{{ $t("global.appointment") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <!-- Start Terminliste -->
                  <terminliste
                    :shown="anzeige == 0 ? true : false"
                    :arbeitsplatz="id"
                  />
                  <!-- Ende Terminliste -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <message-box
      id="arbeitsplatz-konflikt-modal"
      headerText="arbeitsplatzkonflikt"
      text="Beim Speichern ist ein Konflikt mit einem bestehenden arbeitsplatz aufgetreten!"
      :ok="true"
      :cancel="false"
      okText="Ok"
    />

    <loeschen-modal
      id="arbeitsplatz-loeschen-modal"
      @confirmed="arbeitsplatzloeschen"
    />

    <rma-modal
      id="display-rma-modal"
      :arbeitsplatz="arbeitsplatz"
      @confirmed="speichern"
    />

    <!--<change-watcher-alert />-->
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import Terminliste from "@/components/Reiter/Terminliste";

import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";

import LoeschenModal from "@/components/Modals/LoeschenModal.vue";
import RmaModal from "@/components/Modals/DisplayRmaModal.vue";
import MessageBox from "@/components/Modals/Messagebox.vue";
import { LADE_AUSWAHLWERTE_RESSOURCEN } from "@/store/ressourcen/actions.type";

export default {
  name: "Arbeitsplatz",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    LoeschenModal,
    MessageBox,
    Terminliste,
    RmaModal,
  },
  metaInfo() {},
  mixins: [page, ChangeWatcher],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      arbeitsplatz: {
        bezeichnung: null,
        displayNr: null,
        raum: null,
        displayStatus: null,
      },
      isDESKAAdmin: false,
    };
  },
  computed: {
    raeume: {
      get() {
        return this.$store.state.ressourcen.raeume;
      },
    },
  },
  created() {
    if (this.id != null && this.id != 0) {
      Api.get("arbeitsplatz/", { params: { id: this.id } }).then((response) => {
        this.initializearbeitsplatz(response.data);
      });
    } else this.initializearbeitsplatz(this.arbeitsplatz);
  },
  mounted: function () {
    this.$store.dispatch(`ressourcen/${LADE_AUSWAHLWERTE_RESSOURCEN}`);

    this.isDESKAAdmin =
      this.gruppen.findIndex(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").DESKAADMIN
      ) == -1
        ? false
        : true;
  },
  methods: {
    abbrechen() {
      this.$router.push({ name: "Arbeitsplatzliste" });
    },
    getArbeitsplatz() {
      Api.get("arbeitsplatz/", { params: { id: this.id } }).then((response) => {
        this.initializearbeitsplatz(response.data);
        window.location.reload(true);
      });
    },
    initializearbeitsplatz(arbeitsplatz) {
      this.arbeitsplatz = arbeitsplatz;

      /*f (this.arbeitsplatz.verantwortlich == null) {
        this.arbeitsplatz.verantwortlich = this.angemeldeterMitarbeiter.person;
      }*/

      this.editable = this.berechtigungen.m_ressourcen.update;

      this.initChangeWatcher(this.arbeitsplatz);
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.arbeitsplatz);

      if (!this.id) {
        Api.post("arbeitsplatz/", json, {
          params: { type: 1 },
        })
          .then((response) => {
            this.postSave(response);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
            this.$router.replace({ name: "Arbeitsplatzliste" });
          });
      } else {
        Api.put("arbeitsplatz/", json, {
          params: { id: this.arbeitsplatz.id, type: 1 },
        })
          .then((response) => {
            this.postSave(response);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
            window.location.reload(true);
          });
      }
    },

    postSave(response) {
      this.initializearbeitsplatz(response.data.arbeitsplatz);

      this.$notify({
        type: "success",
        title: this.$t("notification.actionsuccessful"),
        text: "arbeitsplatz erfolgreich gespeichert.",
      });

      this.$router.push({
        name: "Arbeitsplatzliste",
      });
    },
    displayFreigeben() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.arbeitsplatz);
      Api.put("arbeitsplatz/", json, {
        params: { id: this.arbeitsplatz.id, type: "reset" },
      })
        .then((response) => {
          this.postSave(response);
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
          window.location.reload(true);
        });
    },
    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "arbeitsplatz-loeschen-modal");
    },

    oeffneRmaModal() {
      this.$bus.$emit("openRmaModal", "display-rma-modal");
    },
    schliesseRmaModal() {
      $("#modal-loeschen").modal("hide");
    },

    arbeitsplatzloeschen() {
      Api.delete("arbeitsplatz/", {
        params: { id: this.id },
      }).then(() => {
        this.$router.push({ name: "Arbeitsplatzliste" });
      });
    },
  },
};
</script>

<style>
</style>
